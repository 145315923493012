.App {
  text-align: center;
  }

body {
  margin: 0;
  padding: 0;
}

@media screen and (max-width: 992px) {
    .textbody {
	text-align: justify;
    }
}

@font-face {
  font-family: Avenir;
  /* ttc */
  src: url(./components/fonts/Avenir.ttf)  format('truetype');
  font-style: normal;
  font-weight: normal;
  }

  @font-face {
    font-family: CenturyGothic;
    src: url(./components/fonts/911Fonts.com_CenturyGothicRegular__-_911fonts.com_fonts_mhpY.ttf);
    font-style: normal;
    font-weight: normal;
  }

* {
  font-family: 'Avenir';
}

a {
  text-decoration: none
}

.button-text {
  text-align: center;
  padding-left: 25px;
  padding-right: 25px;
}

.logo {
  text-align: left;
  padding-left: 30px;
  transform: translateY(-15px);
  position: absolute;
  width: 300px;
 }

 @media screen and (max-width: 1200px) {
 .logo {
  /*width: 100%;*/
  padding-left: 20px;
  /*padding-top: 190px;*/
  transform: translateY(-15px);
 }
}

 @media screen and (max-width: 750px) {
  .logo {
    padding-left: 15px;
    transform: translateY(0);
    width: 200px;
   }
  #logoit {
    width: 200px;
   }
   #logode {
    width: 200px;
   }
}

#navigation {
  padding-right: 70px;
  /*height: 100px;*/
  width: 100%;
  background-color: #00652b;
 }

 @media screen and (max-width: 1200px) {
  #navigation {
    padding-right: 10px;
   }
}

.icons {
  margin: 5px 5px 5px 0px;
  color: white;
  background-color: transparent;
  border: solid white 1px;
 
}

.icons:hover {
  margin: 5px 5px 5px 0px;
  color: #00652b;
  background-color: white;
}

#it {
  font-size: 14pt;
  }

#de {
  font-size: 14pt;
  display: none;
}

@media screen and (max-width: 480) {
  .toolbar {
    padding-left: 0;
    padding-right: 0;
  }
    
}

.menubox {
  text-align: right;
  width: 100%;
  display: flex;
  background-color: #00652b;
  color: white;
}

.menu {
  float: right;
  background-color: #00652b;
  height: 50px;
  width: 100%;
  text-align: right;
  vertical-align: middle;
  padding-right: 85px;
  text-transform: capitalize;
  padding-bottom: 15px;
  margin-left: auto;
}

.menu ul {
  display:inline-flex;
}

.menu li {
  list-style-type: none;
}

@media screen and (max-width: 920px) {
  .menu ul {
    position: absolute;
    top: 149px;
    left: 0;
    flex-direction: column;
    width: 100%;
    height: auto;
    padding-right: 0px;
    display: none;
    background-color: rgba(0, 101, 43, .7);
    padding-top: 50px;
    padding-bottom: 3px;
  }
  
  .menu li {
    text-align: center;
    margin-right: 60px;
    list-style-type: none;
  }
}

@media screen and (max-width: 1200px) {
  .menu.expanded ul {
   display: block;
  } 
}

.hamburger {
  border: 0;
  background-color: transparent;
  cursor: pointer;
  color: white;
  position: absolute;
  top: 90px; /* 120 */ 
  right: 30px;
  display: none;
}

@media screen and (max-width:920px) {
  .hamburger {
    display: block;
  }
}

.popover {
  background-color: #00652b;
  color: white;
  font-size: 1pt;
  padding: 10px 20px 10px 20px;
  border-top: white solid 1px;
}

.link {
  color: white;
  z-index: 0;
  text-decoration: none;
  font-size: 16pt;
}

.link:focus {
  font-weight: bold;
}

.link:hover {
  font-weight: bold;
}

.link:active {
  font-weight: bold;
}

.opacity {
  z-index: 1;
  opacity: .8;
}

@media screen and (max-width: 992px) {
  .popover {
    padding: 10px 20px 10px 20px;
  }
}

@media screen and (max-width: 1200px) {
  .opacity {
    opacity: 1;
  }
}
 
.mainimagebox {
  width: 100%;
  height: 420px; /* 720 */
  padding-bottom: 0px;
}

.mainimage {
  width: 100%;
  object-fit: cover;
  object-position: center;
  height: 100%;
}

.maintextbox {
  padding-top: 50px;
  padding-left: 200px;
  padding-right: 200px;
  padding-Bottom: 70px;
}

@media screen and (max-width: 992px) {
  .maintextbox {
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
    padding-Bottom: 20px;
  }

  .mainimagebox {
    width: 100%;
    height: 520px;
    padding-bottom: 0px;
  }
}

.whitetitle {
  font-size: 32pt; /* 42 */ 
  font-weight: 600;
  color:white;
} 

.greentitle{
  font-size: 26pt;
  color: #00652b;
  width: 100%;
}

.small-greentitle {
  margin: 5px;
  text-align: left;
  font-size: 14pt;
  font-weight: bold;
  color: #00652b;
}
.text {
  font-size: 18pt;
  color: black;
  padding-top: 50px;
  
}

.wtext {
  font-size: 18pt;
  color: white;
  padding-top: 50px;
  font-family: inherit;
}

.blacktext {
  font-size: 18pt;
  color: black;
  padding: 0px 70px 0px 70px;
  font-family: inherit;
}

.textgb {
  font-size: 18pt;
  color: #00652b;
  font-weight: 600;
  font-family: inherit;
}

.ctext {
  font-size: 16pt;
  color: black;
  padding-top: 50px;
  font-family: inherit;
}

.ctextgb {
  font-size: 16pt;
  color: #00652b;
  font-weight: 600;
  font-family: inherit;
}


.title {
  font-size: 32pt; /* 42 */
  font-weight: 600;
  color:#00652b;
} 

@media screen and (max-width: 600)
{
  .title {
    font-size: 24pt;
  }
}

@media screen and (max-width: 992px) {
  .title {
    font-size: 32pt;
  } 
  
  .whitetitle {
    font-size: 32pt;
  } 
  
  .greentitle{
    font-size: 20pt;
  }
  
  .text {
    font-size: 16pt;
  }

  .wtext {
    font-size: 16pt;
  }

  .blacktext {
    font-size: 16pt;
    padding: 0px 20px 0px 20px;
  }

  .textgb {
    font-size: 16pt;
  }
}

.greybox {
  padding: 70px 50px 70px 50px;
  background-color: #f0f0f0;
  text-align: center;
}

.generaliconsbox{
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
}

.iconbox {
  width: 400px;
  text-align: center;
  padding: 20px 10px 20px 10px;
  margin-left: auto;
  margin-right: auto;
  font-size: 22pt;
  color: #00652b;
  vertical-align: top;
  text-transform: uppercase;
}

@media screen and (max-width: 992px) {
.greybox {
  padding: 20px 10px 10px 20px;
  background-color: #f0f0f0;
  text-align: center;
}

.iconbox {
  font-size: 18pt;
}
}

.ber-image {
  width: 100%;
  object-fit: cover;
}



.greenboxphoto {
  text-align: right;
  padding: 80px 0px 70px 70px;
  
}

@media screen and (max-width: 1200px) {
  .greenboxphoto {
    text-align: center;
    padding: 50px 20px 20px 20px;
    
  }
}
.logo2 {
  margin-top: 40px;
  position: absolute;
  margin-left: -70px;
}

@media screen and (max-width: 1200px) {
  .logo2 {
    margin-top: 40px;
    position: absolute;
    margin-left: 20px;
  }
}

.unser {
  position: relative;
  background-color: #00652b;
}

.unser-textbox {
  width: 50%;
  padding: 70px;
  color: white;
  text-align: left;
  font-size: 18pt;
  position: relative;
  font-family: inherit;
}

.unserimagebox {
  width: 100%;
  height: 750px;
 }

 @media screen and (max-width: 1200px) {
  .unserimagebox {
    height: 550px;
 }
}

.unsdien {
  background-color: #97c00e;
} 
  
.unsdien-textbox {
  width: 50%;
  padding: 70px;
  color: white;
  text-align: left;
  font-size: 18pt;
}

.beratungen {
  width: 100%;
  height: 650px;
}

.beratungen-im {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media screen and (max-width: 1200px) {
  .unser-textbox {
    font-size: 16pt;
    padding: 30px;
  }
  
  .unsdien-textbox {
    width: 50%;
    padding: 70px;
    color: white;
    text-align: left;
    font-size: 18pt;
  }

  .beratungen {
    height: 550px;
  }
}

#bottom {
  padding: 50px 100px 100px 100px;
  font-family:inherit
}

@media screen and (max-width: 1200px) {
  #bottom {
    padding: 30px 30px 30px 30px;
    font-family:inherit
  }
}

.line {
  margin: 50px 110px 0px 110px;
  border-top:solid 1px;
  border-top-color: #00652b;
}

@media screen and (max-width: 1200px) {
  .line {
    margin: 50px 30px 0px 30px;
    border-top:solid 1px;
    border-top-color: #00652b;
  }
}

.cards {
  max-width: 600px;
  border-radius: 0px;
  border-color: #00652b;
  text-align: left;
  margin: auto;
  font-family: inherit;  
}

.cards-text-gt {
  padding-left: 5px;
  /*height: 330px;*/
  max-height: 380px;
  overflow: hidden;
  position: relative;
}

.cards-text-gt:after{
  content: "";
  text-align: right;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 5em;
  /*background: linear-gradient(to bottom, rgba(255, 255, 255, 0), white 100%);*/
  pointer-events: none;
}


.cards-text-d:after{
  content: "";
  text-align: right;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 5em;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0), 
  white 100%);
  pointer-events: none;
}

.cards-text-d {
  position: relative;
  overflow: hidden;
  padding-left: 5px;
  height: 150px;
}

.cardsbutton {
  font-family: inherit;
  padding: 25px;  
}

.cardscarousel {
  max-width: 600px;
  border-radius: 0px;
  border: #00652b solid 1px;
  text-align: left;
  min-height: 900px;
  margin: 20px;
}


.cardbox {
  padding: 50px 50px 50px 50px;
 }

.arrows {
  font-size: 60pt;
  background-color: transparent;
  border: none;
  color: #00652b;
  box-shadow: none;
}

@media screen and (max-width: 1200px) {
  .cardbox {
    padding: 20px 20px 20px 20px;
   
  }
  .arrows {
     font-size: 30pt;
  }
}

#more {
  display: none;
}

#btndown {
  display: inline;
}

#btnup {
  display: none;
}

#btn {
  font-size: 18pt;
  color: #00652b;
  font-weight: 600;
  background-color: transparent;
  text-transform: none;
  font-family: inherit;
}

#fbicon {
  color: black;
  border: solid 1px;
  border-radius: 0px;
  padding: 5px;
}

.footerlinks {
  text-align: left;
  color: black;
  font-family:'CenturyGothic', sans-serif;
  font-size: 14pt;
  text-decoration: none;
 
}

.laimburg-logo{
  width: 100%;
  padding-left: 10px;
  text-align: left;
}

.photogallery {
  height: 350px;
  width: 100%;
  padding: 5px;
}

.gallery{
  width: 100%;
  object-fit: cover;
  height: 100%;
}

.form {
  width: 100%;
}

.carousel-g {
  display: inline;
}

.carousel-v {
  display: none;
}

@media screen and (max-width: 992px) {
  .carousel-g {
    display: none;
  }
  
  .carousel-v {
    display: inline;
  }
}

.label {
  font-size: 10pt;
}

.carousel {
  padding: 0px 50px 70px 50px;
}

@media screen and (max-width: 1200px) {
  .carousel {
    padding: 0px 10px 20px 10px;
  }
}

.footer {
  margin: 50px 110px 0px 110px;
  
}

@media screen and (max-width: 1200px) {
  .footer {
    margin: 50px 30px 0px 30px; 
  }
}

.pad {
  padding-top: 20px;
}

.ul {
  margin-left: 20px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/*
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

*/


/* Button styling */

.green-button {
  padding: 8px 22px;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12);
  border-radius: 0;
  color: white;
  background-color: #00652b;
  /*font-weight: bold;*/
  font-size: 14pt;
  text-transform: uppercase;
  min-width: 200px;
  min-height: 40px;
}


.hyperlink
{
  padding-left: 5px;
  padding-right: 5px;
  color: midnightblue;
  font-size: larger;
  
}
.formButton {
  font-family: Avenir;
  /* ttc */
  src: url(./components/fonts/Avenir.ttf)  format('truetype'); 
  font-weight: 500;
  font-size: 0.9375rem;
  box-sizing: border-box;

  color: white;
  background-color: #00652b;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  /*box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12);*/
  padding: 8px 22px;
  display: inline-flex;
  text-transform: uppercase;
  line-height: 1.75;
  border-radius: 4px;
  justify-content: center;
  color: #fff;
  min-width: 190px;
}

.error {
  color: red;
}

.errormessage {
  color: red;
  border-color: red;
}


.responive {
  background-color: yellow;
}
@media (max-width: 1200px)
{
  .responive {
    background-color: rebeccapurple;
  }
}
@media (max-width: 992px)
{
  .responive {
    background-color: red;
  }
}
@media (max-width: 768)
{
  .responive {
    background-color: greenyellow;
  }
}
@media (max-width: 576)
{
  .responive {
    background-color: burlywood;
  }
}

.css-1nvnyqx-MuiPaper-root-MuiDrawer-paper {
  opacity: 0.85;
}

.accordion-icon {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
}

.center {
  text-align: center;
}